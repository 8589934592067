<template>
  <!-- ======= Footer ======= -->
  <footer id="footer" class="footer">
    <div class="copyright col-sm-12">
      &copy; Copyright <strong><span>OBED-EDOM</span></strong>. Todos los derechos reservados
    </div>
    <div class="credits col-sm-12">
      <!-- All the links in the footer should remain intact. -->
      <!-- You can delete the links only if you purchased the pro version. -->
      <!-- Licensing information: https://bootstrapmade.com/license/ -->
      <!-- Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/nice-admin-bootstrap-admin-html-template/ -->
      Diseñado con <a href="https://bootstrapmade.com/">BootstrapMade</a>
    </div>
  </footer><!-- End Footer -->

</template>